import * as React from "react";
import { AnimatedLink } from "../components/AnimatedLink/AnimatedLink";
import PageSection from "../components/PageSection/PageSection";
import PageTemplate from "../components/PageTemplate/PageTemplate";
import TricolorMorphDef from "../components/TricolorMorphDef/TricolorMorphDef";
import { PageComponent } from "../types/gatsby.types";
import './404.scss';

const Page404: PageComponent = props => {
  return (
    <PageTemplate 
      {...props} // always include
      className="Page404"
      title="Page Not Found"
      description="404: Page Not Found."
    >
      <TricolorMorphDef />
      <PageSection id="Page404Content" className="Page404Content">
        <span className="Page404ErrorCode">404</span>
        <h1>Page not found</h1>
        <p>
          Sorry
          {" "}<span role="img" aria-label="Pensive emoji">😔</span>{" "}
          we couldn’t find what you were looking for.
          <br />
          {process.env.NODE_ENV === "development" ? (
            <>
              <br />
              <code>Developer Message: </code> Try creating a page in <code>src/pages/</code>.
              <br />
            </>
          ) : null}
          <br />
          <AnimatedLink to="/" title="Home">Go home</AnimatedLink>.
        </p>
      </PageSection>
    </PageTemplate>
  )
}

export default Page404
